import {config} from '@/utils'

export default {
    name: 'Console',
    created() {
        if(this.usertype ==1){
            this.personinfo()
        }
        
        this.panel()
        this.get_application_status()
    }, 
    computed:{
        fontstyle(){
            return (status_text) => {
                if (status_text === "已过期" ){
                    return { 'color': 'red'}
                }else if (status_text === "生效中"){
                    return { 'color': '#52C41A '}
                }else if (status_text === "已用完"){
                    return { 'color': ' #414b60'}
                }
            }
        }
    },
    methods: {
        //首页数据
        panel(){
            this.$api.user.panel().then(res=>{
                if(res.status_code == 0){
                    this.unfold=res.obj
                   
                }else {
                    this.$Message.error({
                       content: res.status_msg,
                       duration: 2
                   })
               }
            })

        },
        // 
        personinfo(){
            this.$api.user.personinfo().then(res=>{
                if(res.status_code == 0){
                    this.personinfoObj=Number(res.obj.status)
                    this.personError=res.obj.error
                   
                }else {
                    this.$Message.error({
                       content: res.status_msg,
                       duration: 2
                   })
               }
            })

        },
        //获取订阅状态
        get_application_status(){
            let param={
                application_number:19
            }
            this.$api.launch_contract.get_application_status(param).then(res=>{
                if(res.status_code == 0){
                    this.charge=res.obj
                    this.totalrte=this.charge.total_detail.remain
                    this.ns_remain=this.charge.total_detail.ns_remain
                    this.jc_remain=this.charge.total_detail.jc_remain
                    localStorage.setItem('remain',this.charge.total_detail.remain)
                    localStorage.setItem('application_status',this.charge.application_status)
                    if(this.charge.data_retain_notice_message !='' && this.charge.application_status ==2){
                        this.bola=true
                        return
                    }
                    if(localStorage.getItem('alert') ==null  && this.charge.application_status !=3  && this.charge.application_status !=2 && this.charge.purchase_message !=''){
                        this.bole=true
                        return
                    }
                   
                }else if(res.status_code == 99998) {
                    this.bole=false
                    this.bola=false
                    this.totalrte=0
                    localStorage.setItem('remain',0)
                }else {
                    this.$Message.error({
                       content: res.status_msg,
                       duration: 2
                   })
               }
            })
        },
        //关闭提示
        colse(){
            localStorage.setItem('alert',1)
        },
        //跳转订阅
        toBuy() {
            let tempwindow=window.open('_blank');
            tempwindow.location=this.config.domain+'/console/goods/center'+'?token='+_global.token+'&num=2';
        },
        //查看订阅详情
        setmeal(){
            this.basic=true;
            let param={
                page:1,
                page_number:5,
                application_number:19
            }
            this.getList(param)
        },
        //订阅详情列表
        getList(param){
            this.$api.launch_contract.get_order_list(param).then(res=>{
                if(res.status_code !=0){
                    this.$Message.error({
                        content: res.status_msg,
                        duration: 2
                    })
                }else{
                    this.itemsData=res.obj
                    res.list.forEach(et => {
                        if(et.status ==1){
                            et.status_text="生效中"
                        }
                        et.items.forEach(at => {
                            if (at.quota_type ==0) {
                                at.total_text=at.total
                                at.usage_text=at.usage
                                at.remain_text=at.remain
                            }
                        });
                    });
                    this.labList=res.list
                }
            })
        },
        //分页
        changeTablePage(page){
            let param={
                page:page,
                page_number:5,
                application_number:19
            }
            this.getList(param)

        },
        //新增存证
        add(v){
            if(v==1){
                this.$router.push({
                    path:'/cate/addCreation',
                })

            }else if(v==2){
                this.$router.push({
                    path:'/archive/addFile',
                })
            }else if(v==3){
                this.$router.push({
                    path:'/tore/addContract',
                })
                
            }else{
                this.$router.push({
                    path:'/sever/extract',
                })
                
            }
        },
        //查看
        check(v){
            if(v==1){
                this.$router.push({
                    path:'/cate/oups',
                })

            }else if(v==2){
                this.$router.push({
                    path:'/archive/files',
                })
            }else if(v==3){
                this.$router.push({
                    path:'/tore/serve',
                })
                
            }else{
                this.$router.push({
                    path:'/sever/extract',
                })
                
            }

        },
        go(){
            if(this.usertype ==1){
                this.$router.push({
                    path:'/acctuser',
                })

            }else if(this.usertype==2){
                this.$router.push({
                    path:'/acctuser/firm/approve',
                })

            }
        }
   
    },

    data () {
        return {
            config,
            personinfoObj:'',
            personError:'',
            userInfo: _global.userInfo,
            avator_url:_global.userInfo.avator_url,
            bole:false,
            bola:false,
            usertype:Number(localStorage.getItem('usertype')),
            unfold:{},//首页数据
            charge:[],//订阅信息
            totalrte:0,
            ns_remain:0,
            jc_remain:0,
            basic:false,//查看订阅详情
            cert_status:Number(localStorage.getItem('cert_status')),//实名认证状态
            columns1: [
               
                {
                    title: '应用名称',
                    align:'center',
                    key: 'application_name'
                },
                {
                    title: '使用周期',
                    width:240,
                    align:'center',
                    key: 'date_range_text'
                },
                {
                    title: '存证机构',
                    // width:240,
                    align:'center',
                    key: 'evidence_type_text'
                },
                {
                    title: '总可用量',
                    align:'center',
                    key: 'total_text'
                },
                {
                    title: '已使用',
                    align:'center',
                    key: 'usage_text'
                },
                {
                    title: '剩余用量',
                    align:'center',
                    key: 'remain_text'
                }
            ],
            labList:[],//订阅详情列表
            itemsData:{},//列表分页
        }
    }
}
